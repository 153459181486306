/*=require ./includes/blocks/*.js*/

// Countdown Timer
let dateTime = $('.Timer').data('datetime');

$('.Timer').countdown(dateTime, function (event) {
	let months = parseInt(event.strftime('%-m')),
			days = parseInt(event.strftime('%-D')),
			hours = parseInt(event.strftime('%-H'));

	$('.Timer_item-months .Timer_number').text(parseInt(months / 10) + '' + months % 10);
	$('.Timer_item-days .Timer_number').text(parseInt(days / 10) + '' + days % 10);
	$('.Timer_item-hours .Timer_number').text(parseInt(hours / 10) + '' + hours % 10);
});

// Fancybox
$('[data-fancybox').fancybox({
	touch: false,
	btnTpl: {
		smallBtn: '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small Popup_close" title="{{CLOSE}}">' +
      '<svg version="1.0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.9 13.5l-1.4 1.4-3.5-3.5-3.5 3.5-1.4-1.4 3.5-3.5-3.5-3.5 1.4-1.4 3.5 3.5 3.5-3.5 1.4 1.4-3.5 3.5 3.5 3.5z"/></svg>' +
      "</button>"
	}
});